<template>
  <div class="orders-manage">
      <div class="orders-manage-tools">
          <el-input v-model="search" style="width:300px;"  placeholder="חיפוש" />
          <input v-model="fromDate" type="date">
          <input v-model="toDate" type="date">
          <el-button @click="handle_print" type="info" round>הדפסה</el-button>
          <el-button @click="handle_export_to_scv('current')" type="success" round>ייצוא לאקסל</el-button>
      </div>
      <div class="orders-manage-dashborad">
          <div class="dashboard-display">
              <h4>סך רשומות</h4>
              {{sorted_records.length}}
          </div>
          <div class="dashboard-display">
              <h4>סך כמויות</h4>
              {{calculate_total_amounts}}
          </div>
      </div>
      <div class="orders-manage-options">
            <el-button @click="handle_show_records_by_status('חדש')" type="success">חדשים</el-button>
            <el-button @click="handle_show_records_by_status('טופל')" style="margin-right:5px;" type="danger">טופלו</el-button>
      </div>
      <div class="orders-manage-content">
          <table id="table">
              <tr>
                  <th style="cursor:pointer;" @click="handleSortTable('נוצר בתאריך', !selectedSorted.order)">נוצר בתאריך</th>
                  <th style="cursor:pointer;" @click="handleSortTable('סניף', !selectedSorted.order)">סניף</th>
                  <template v-for="item in items_list" :key="item.uid">
                      <th>{{item.item_name}}</th>
                  </template>
                  <th>סך כמות</th>
                  <th>סטטוס</th>
                  <th class="no-print">פעולות</th>
                  <th class="no-print"><Checkbox v-model="checkeAll" :binary="true" /></th>
              </tr>
              <h1 v-if="sorted_records.length==0" style="color:#333">אין רשומות</h1>
              <template v-for="record in sorted_records" :key="record.uid">
                  <tr>
                      <td>{{new Date(record.created_at.seconds * 1000).toLocaleDateString('he')}}</td>
                      <td>{{record.branche}}</td>
                      <template v-for="item in record.items" :key="item.uid">
                          <td>{{item.amount}} {{item.type}}</td>
                      </template>
                      <td>{{record.items.reduce((acc, cur)=>acc+cur.amount,0)}}</td>
                      <td style="color:green; font-weight:500;" v-if="record.status=='חדש'">
                          חדש
                      </td>
                      <td style="color:red; font-weight:500;" v-if="record.status=='טופל'">
                          טופל
                      </td>
                      <td class="no-print"><i @click="handle_edit(record)" style="cursor:pointer;" class="material-icons">edit</i></td>
                      <td class="no-print"><Checkbox name="record" :value="record" v-model="check_records"/></td>
                  </tr>
              </template>
          </table>
      </div>
      <div v-if="show_edit_form" class="edit-record">
          <NewOrder @close="show_edit_form=false" @update="handle_update" :edit_record="selected_record"/>
      </div>
  </div>
  <OrdersOptions @export_to_ecxel="handle_export_to_scv" @delete_records="handle_delete_records" @modify_records="handle_modify_records" :status="selected_status" v-if="show_orders_options" @close="show_orders_options=false; check_records=[]; checkeAll=false " />
  <Loader v-if="isPending" />
</template>

<script>
import { ref } from '@vue/reactivity'
import {get_all_items_from_db,get_orders_by_status} from '../../../../../Methods/Pastramas_funcs'
import Checkbox from 'primevue/checkbox';
import OrdersOptions from './Orders/OrdersOptions.vue'
import { computed, watch } from '@vue/runtime-core';
import { projectFirestore } from '../../../../../firebase/config';
import XLSX from "xlsx";
import Loader from '../../../officeRequests/components/manage_side/loader/Loader.vue';
import NewOrder from '../Orders/NewOrder.vue'
export default {
    components:{Checkbox,OrdersOptions,Loader,NewOrder},
    setup(){
        const selected_record = ref(null)
        const show_edit_form = ref(false)
        const isPending = ref(false)
        const selected_status = ref('')
        const show_orders_options = ref(false)
        const checkeAll = ref(false)
        const check_records = ref([])
        const search = ref('')
        const fromDate = ref('')
        const toDate = ref('')

        const items_list = ref([])
        const records = ref([])
        const handle_update = async(status)=>{
            isPending.value = true
            show_edit_form.value = false
            selected_record.value = null
            records.value = await get_orders_by_status(status)
            isPending.value = false 

        }
        const handle_edit = (record)=>{
            selected_record.value = record
            show_edit_form.value = true
        }
        const calculate_total_amounts = ref(computed(()=>{
            let total = 0 
            sorted_records.value.forEach(record=>{
                total+= record.items.reduce((acc, cur)=>acc+cur.amount,0)
            })
            return total
        }))

        const sorted_records = ref(computed(()=>{
            if (selectedSorted.value.name == "") return filter_by_search.value
            if (selectedSorted.value.name == "נוצר בתאריך") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  new Date(a.created_at.seconds * 1000) > new Date(b.created_at.seconds * 1000)
                        ? 1
                        : new Date(b.created_at.seconds * 1000)  > new Date(a.created_at.seconds * 1000) 
                        ? -1
                        : 0;
                    }
                    return new Date(a.created_at.seconds * 1000)  > new Date(b.created_at.seconds * 1000) 
                    ? -1
                    : new Date(b.created_at.seconds * 1000)  > new Date(a.created_at.seconds * 1000) 
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "סניף") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.branche > b.branche
                        ? 1
                        : b.branche  > a.branche
                        ? -1
                        : 0;
                    }
                    return a.branche  > b.branche 
                    ? -1
                    : b.branche  > a.branche
                    ? 1
                    : 0;
                });
            }
        }))
        const handleSortTable = (name, order) => {
            selectedSorted.value.name = name;
            selectedSorted.value.order = order;
        };
        const selectedSorted = ref({
            name: "",
            order: false
        });
        const filter_by_search = ref(computed(()=>{
            if(search.value=='') {
                if(fromDate.value && toDate.value){
                        return records.value.filter(record=>{
                            if(stringTodate(fromDate.value)<=timestampToDate(record.created_at) &&
                            stringTodate(toDate.value)>=timestampToDate(record.created_at) ){
                                return record
                            }
                        })
                }
                return records.value;   
            }else{
                let results =  records.value.filter(record=>{
                    if(record.branche.includes(search.value)) return record
                    if((new Date(record.created_at.seconds * 1000).toLocaleDateString('he').includes(search.value))) return record
                })
                if(fromDate.value && toDate.value){
                    return results.filter(record=>{
                        if(stringTodate(fromDate.value)<=timestampToDate(record.created_at) &&
                        stringTodate(toDate.value)>=timestampToDate(record.created_at) ){
                            return record
                        }
                    })
                }
                return results
            }
            
        }))
        const handle_show_records_by_status = async(status)=>{
            isPending.value = true
            records.value = await get_orders_by_status(status)
            isPending.value = false
        }

        const init = async()=>{
            isPending.value = true
            items_list.value = await get_all_items_from_db()
            records.value = await get_orders_by_status('חדש')
            isPending.value = false
        }

        // for dates
        const stringTodate=(dateString)=>{
            const d=new Date(dateString)
            d.setHours(0,0,0,0)
            return d
        }
        const timestampToDate=(date)=>{
            const d = new Date(date.seconds * 1000)
            d.setHours(0,0,0,0)
            return d
        }

        const handle_export_to_scv = (type)=>{
            let rows;
            if(type=='marks'){
                rows = JSON.parse(JSON.stringify(check_records.value))
            }
            if(type=='current'){
                rows = JSON.parse(JSON.stringify(filter_by_search.value))
            }
            let workbook = XLSX.utils.book_new();
            let workSheetData = []

             workSheetData.push([
                    'נוצר בתאריך',
                    'סניף',
                    ...items_list.value.map(item=>item.item_name),
                    'סך כמות',
                    'סטטוס'
            ])

             rows.forEach((row)=>{
                 workSheetData.push([
                     new Date(row.created_at.seconds * 1000).toLocaleDateString('he').split('.').join('/'),
                     row.branche,
                     ...row.items.map(item=>`${item.amount} ${item.type}`),
                     row.items.reduce((acc, cur)=>acc+cur.amount,0),
                     row.status
                 ])
             })

            const workSheet = XLSX.utils.aoa_to_sheet(workSheetData)
            workbook.Workbook = {};
            workbook.Workbook.Views = [];
            workbook.Workbook.Views[0] = {};
            workbook.Workbook.Views[0].RTL = true;
            XLSX.utils.book_append_sheet(workbook, workSheet, "מעושנים");
            XLSX.writeFile(workbook, "מעושנים.xlsx");
            
        }

        const handle_modify_records = async(status)=>{
            for(const record of check_records.value){
                await projectFirestore.collection('Applications').doc('ukDiSJTDxi8Bg2D71eQQ').collection('Orders').doc(record.uid)
                .set({
                    status
                },{merge:true})

                const index = records.value.findIndex(_record=>_record.uid==record.uid)
                if(index!=-1) records.value.splice(index,1)
                
                
            }
            check_records.value = []
            checkeAll.value = false
            show_orders_options.value = false
        }

        const handle_delete_records = async()=>{
            for(const record of check_records.value){
                await projectFirestore.collection('Applications').doc('ukDiSJTDxi8Bg2D71eQQ').collection('Orders').doc(record.uid)
                .delete()
                const index = records.value.findIndex(_record=>_record.uid==record.uid)
                if(index!=-1) records.value.splice(index,1)
            }
            check_records.value = []
            checkeAll.value = false
            show_orders_options.value = false
        }

        const handle_print = ()=>{
            var styleElem = document.createElement('style');
            styleElem.type = 'text/css' ;
            var css = `
                    *{
                        direction:rtl;
                    }
                    table {
                        border-collapse: collapse;
                        width: 100%;
                    }
                    table td, table th {
                        border: 1px solid #ddd;
                        padding: 2px;
                    
                    }
                    table tr:nth-child(even){background-color: #f2f2f2;}
                    table tr:nth-child(odd){background-color: #e7e6e6;}
                    table tr:hover {background-color: #ddd;}
                    table td{
                        text-align: center;
                        color: #161D31;
                        font-size: 16px;

                    }
                    table th {
                        padding-top: 4px;
                        padding-bottom: 4px;
                        text-align: center;
                        background-color: #283046;
                        color: white;
                        position: sticky;
                        top: 0;
                    }
                    @media print
                    {    
                        .no-print, .no-print *
                        {
                            display: none !important;
                        }
                    }
            `
            if(styleElem.styleSheet){
                styleElem.styleSheet.cssText = css;
            }
            else{
                styleElem.appendChild(document.createTextNode(css));
            }
            var divToPrint=document.getElementById("table");
            let newWin= window.open('', '', 'height=500, width=500');
            newWin.document.write(divToPrint.outerHTML);
            newWin.document.getElementsByTagName('head')[0].appendChild(styleElem);
            newWin.document.close();
            newWin.print();
        }

        init()

        watch(checkeAll,()=>{
            if(checkeAll.value){
                check_records.value=records.value.map(record=>record)
            }
            else{
                check_records.value=[]
            }
        })
        
        watch(check_records,()=>{
            if(check_records.value.length>0){
                show_orders_options.value=true
                selected_status.value = check_records.value[0].status
            }
            else {
                show_orders_options.value=false
            }
        })
        return{
            filter_by_search,
            search,
            fromDate,
            toDate,
            items_list,
            records,
            check_records,
            checkeAll,
            show_orders_options,
            selected_status,
            handle_modify_records,
            handle_show_records_by_status,
            handle_delete_records,
            handle_export_to_scv,
            selectedSorted,
            handleSortTable,
            sorted_records,
            calculate_total_amounts,
            isPending,
            show_edit_form,
            selected_record,
            handle_edit,
            handle_update,
            handle_print
        }
    }
}
</script>

<style scoped>
    .orders-manage{
        position: relative;
        width: 100%;
        height: 100%;
        background: #fff;
    }
    .orders-manage-tools{
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    .orders-manage-dashborad{
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .dashboard-display{
        width: 200px;
        height: 100%;
        border-radius: 5px;
        background: var(--purple);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color:#fff;
        margin-right: 5px;
    }
    .orders-manage-options{
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .orders-manage-content{
        width: 100%;
        height: calc(100% - 180px);
        overflow:hidden;
        overflow-y: auto;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;
       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 16px;

    }
    table th {
        padding-top: 4px;
        padding-bottom: 4px;
        text-align: center;
        background-color: var(--secondary);
        color: white;
        position: sticky;
        top: 0;
    }
    input[type="date"] {
        height: 35px;
        border: 0.5px solid lightgrey;
        border-radius: 3px;
    }
    input[type="date"]:hover {
        border: 1.1px solid lightblue;
    }
    input[type="date"]:focus {
        outline: none;
        border: 1.1px solid lightblue;
    }
    .edit-record{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 11;
    }
</style>