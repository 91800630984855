<template>
  <div class="new-order"> 
      <div class="new-order-form">
          <div class="new-order-form-header">
              <h2 v-if="!edit_record">טופס הזמנת מעושנים</h2>
              <h2 v-if="edit_record">טופס עריכה הזמנת מעושנים</h2>
              <div @click="handle_exit" class="exit-btn">
                  X
              </div>
          </div>
          <div class="new-order-form-content">
            <div class="new-order-form-branche">
                <h3>בחירת סניף <span style="color:red;">*</span></h3>
                <el-select style="width:100%;" v-model="values.branche" filterable placeholder="בחירת סניף">
                    <el-option
                    v-for="branche in branche_list"
                    :key="branche"
                    :value="branche"
                    >
                    </el-option>
                </el-select>
            </div>
            <template v-for="item in values.items" :key="item.uid">
                <div class="new-order-form-item">
                    <h2>{{item.item_name}}<span style="color:red;"> - {{item.type}}</span></h2>
                    <el-input-number v-if="item.type.includes('ק')" style="width:35%;" v-model="item.amount" :min="0" :step="0.1"  />
                    <el-input-number v-else style="width:35%;" v-model="item.amount" :min="0"  />
                </div>
            </template>
          </div>
          <div class="new-order-form-footer">
               <el-button v-if="!edit_record" @click="handle_submit" style="width:100%" type="success">שליחה</el-button>
               <el-button v-if="edit_record" @click="handle_update" style="width:100%" type="success">עדכון</el-button>
          </div>
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {get_branches_from_db} from '../../../../../Methods/rosman_shivuk_funcs'
import {projectFirestore} from '../../../../../firebase/config'
import {slide_pop_error,alert} from '../../../../../Methods/Msgs'
import {get_all_items_from_db} from '../../../../../Methods/Pastramas_funcs'

export default {
    emits:['close','update'],
    props:['edit_record'],
    setup(props,{emit}){

        const error_msg = ref('')
        const branche_list = ref([])

        const values = ref({
            created_at:new Date(),
            branche:'',
            items:[],
            status:'חדש'
        })
        
        const handle_exit = ()=>{
            emit('close')
        }
        const validation = ()=>{
            if(!values.value.branche){
                error_msg.value = 'עליך לבחור סניף'
                return false
            }
            for(const item of values.value.items){
    
                if(item.amount>0) return true
            }

            error_msg.value = 'עליך להזין כמויות'
            return false
        }
        const handle_submit = async()=>{
            if(!validation()){
                slide_pop_error(error_msg.value)
            }else{
                await save_order_in_db()
                alert('success','הצלחה','הטופס נשלח בהצלחה')
                .then(()=>{
                    emit('close')
                })
            }
        }

        const handle_update = async()=>{
            if(!validation()){
                slide_pop_error(error_msg.value)
            }else{
                await update_order_in_db()
                alert('success','הצלחה','הטופס עודכן בהצלחה')
                .then(()=>{
                    emit('update',values.value.status)
                })
            }
        }
        const update_order_in_db = async()=>{
            await projectFirestore.collection('Applications').doc('ukDiSJTDxi8Bg2D71eQQ').collection('Orders').doc(values.value.uid)
            .set(values.value,{merge:true})
        }
        const save_order_in_db = async()=>{
            const doc = projectFirestore.collection('Applications').doc('ukDiSJTDxi8Bg2D71eQQ').collection('Orders').doc()
            await doc.set({...values.value,uid:doc.id})
        }

        const init = async()=>{
            branche_list.value = await get_branches_from_db()
            if(!props.edit_record){
                values.value.items = await get_all_items_from_db()
            }else{
                values.value = JSON.parse(JSON.stringify(props.edit_record))
                delete values.value.created_at                
            }
        }
        init()
        return{
            error_msg,
            branche_list,
            values,
            handle_submit,
            handle_update,
            handle_exit
        }
    }
}
</script>

<style scoped>
    .new-order{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

    }
    .new-order-form{
        width: 100%;
        max-width: 500px;
        height: 90%;
        background: var(--secondary);
        border-radius: 10px;
        color: #fff;
    }
    .new-order-form-header{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .exit-btn{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 5px;
        width: 30px;
        height: 30px;
        background: var(--danger);
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 500;
        z-index: 12;
        cursor: pointer;
    }
    .new-order-form-content{
        width: 100%;
        height: calc(100% - 100px);
        overflow:hidden;
        overflow-y: auto;
    }
    .new-order-form-branche{
        width: 100%;
        height: fit-content;
        padding: 0 5px;
        margin-bottom: 5px;
    }
    .new-order-form-item{
        width: 100%;
        height: fit-content;
        padding: 0 5px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .new-order-form-footer{
        width: 100%;
        height: 50px;
        padding: 0 5px;
    }
</style>