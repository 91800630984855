import {projectFirestore} from '../firebase/config'

export const get_all_items_from_db = async()=>{
    const docs = await projectFirestore.collection('Applications').doc('ukDiSJTDxi8Bg2D71eQQ').collection('Items').get()
        return docs.docs.map(doc=>{
            return {
                item_name: doc.data().item_name,
                type:doc.data().type,
                amount:0,
                uid:doc.data().uid
            }
    }).sort((a,b)=>(a.item_name > b.item_name) ? 1 : ((b.item_name > a.item_name) ? -1 : 0))
}

export const get_orders_by_status = async(status)=>{
    const docs = await projectFirestore.collection('Applications').doc('ukDiSJTDxi8Bg2D71eQQ').collection('Orders')
    .where('status','==',status).orderBy('created_at','desc').get()
    return docs.docs.map(doc=>doc.data())
}