<template>
  <div class="item-manager">
      <div class="items">
        <div class="search">
        <el-input v-model="search" placeholder="חפש פריט">
            <template #prefix>
                <i class="el-input__icon el-icon-search"></i>
            </template>
        </el-input>
        </div>
        <div class="add-item-btn">
            <el-button @click="show_add_item_dialog=true; edit_item=null;" type="success" icon="el-icon-plus" circle></el-button>
        </div>
        <div class="items-content">
            <template v-for="item in filter_by_search" :key="item.uid">
                    <div class="item">
                        <Item @modify="modify_item" @delete="delete_item" :item="item" />
                    </div>
            </template>
        </div>
      </div>
      <AddItem @updateItem="handle_update_item" :edit="edit_item" @addNewItem="handle_add_new_item" @close="show_add_item_dialog=false" v-if="show_add_item_dialog" />
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import Item from './Item.vue'
import { computed } from '@vue/runtime-core'
import {projectFirestore} from '../../../../../firebase/config'
import AddItem from './AddItem.vue'


export default {
    components:{Item,AddItem},
    setup(){

        const edit_item = ref(null)

        const show_add_item_dialog = ref(false)
        const search = ref('')
        const items = ref([])

        const handle_update_item = async(item)=>{
            await projectFirestore.collection('Applications').doc('ukDiSJTDxi8Bg2D71eQQ').collection('Items').doc(item.uid)
           .set(item,{merge:true})
            const index = items.value.findIndex(_item=>_item.uid==item.uid)
            if(index!=-1) items[index]=item
            show_add_item_dialog.value = false
        }
        const handle_add_new_item = async(item)=>{
           await add_new_item_to_db(item)
           show_add_item_dialog.value = false
        }

        const filter_by_search = ref(computed(()=>{
            if(search.value=='') return items.value
            
            return items.value.filter(item=>{
                if(item.item_name.includes(search.value))return item
            })
        }))
        
        const delete_item=(uid)=>{
            const index = items.value.findIndex(item=>item.uid==uid)
            if(index!=-1){
                items.value.splice(index,1)
            }
        }
        const modify_item=(item)=>{
           edit_item.value = item
           show_add_item_dialog.value = true
        }

        const add_new_item_to_db = async(item)=>{
            const doc = projectFirestore.collection('Applications').doc('ukDiSJTDxi8Bg2D71eQQ').collection('Items').doc()
            item.uid = doc.id
            await doc.set(item)
            items.value.push(item)
        }
        const get_items_from_db = async()=>{
            const docs = await projectFirestore.collection('Applications').doc('ukDiSJTDxi8Bg2D71eQQ').collection('Items').get()
            items.value = docs.docs.map(doc=>doc.data())
        }

        get_items_from_db()

        return{
            delete_item,
            modify_item,
            handle_add_new_item,
            add_new_item_to_db,
            search,
            filter_by_search,
            show_add_item_dialog,
            edit_item,
            handle_update_item
        }
    }
}
</script>

<style scoped>
    .item-manager{
        position: relative;
        padding: 10px 0 0 0 ;
        width: 100%;
        height: 100%;
        flex-direction: column;
        display: flex;
        align-items: center;
    }
    .items{
        width: 100%;
        max-width: 500px;
        height: 100%;
        text-align: center;
        overflow:hidden;
    }
    .item{
        margin-bottom: 5px;
        width: 100%;
        height: 50px;
    }
    .search{
        width: 100%;
        height: 40px;
    }
    .add-item-btn{
        width: 100%;
        height: 45px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .items-content{
        width: 100%;
        height: calc(100% - 90px);
        overflow:hidden;
        overflow-y: auto;
    }
</style>