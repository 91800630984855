<template>
  <div class="manage-main">
      <div v-if="state==null" class="manage-main-options">
        <button @click="state='ItemManager'" class="option-btn">
            ניהול מעושנים
        </button>
        <button @click="state='OrdersManage'" class="option-btn manage-btn">
            דוח הזמנות
        </button>
      </div>
      <div v-if="state" class="manage-main-content">
          <component :is="state" />
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import ItemManager from './ItemManager.vue'
import OrdersManage from './OrdersManage.vue'
export default {
    components:{ItemManager,OrdersManage},
    setup(){
        
        const state = ref(null)
        return{state}
    }
}
</script>

<style scoped>
  .manage-main{
      width: 100%;
      height: 100%;
  }
  .manage-main-options{
      width: 100%;
      max-width: 500px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  .manage-main-content{
      width: 100%;
      height: 100%;
  }
</style>